import { Button, Card, Col, Row } from 'antd';
import { SBBar, SBLine } from 'common/components';
import {
    BLUE,
    GRAY,
    GREEN,
    LIGHT_GREEN,
    LIGHT_ORANGE,
    LIGHT_RED,
    LIGHT_YELLOW,
    ORANGE,
    RED,
    STRESS_ZONES,
    YELLOW
} from 'common/constants';
import {
    formatNumberToFixedDigits,
    formatSeconds,
    generateGradientColor,
    getDifferenceInSeconds,
    oxygenGradient
} from 'common/utils';
import { flatMap, map, reduce, chain } from 'lodash';
import propTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    CROWDING_CHART_CONFIG,
    GRADIENT_CHART_CONFIG,
    OXYGEN_CHART_CONFIG,
    TEMPERATURE_CHART_CONFIG
} from './chartConfig';

import { BUTTON_BLUE_SELECTED, BUTTON_BLUE_UN_SELECTED, EFFICIENCY_TOGGLE } from 'common/constants';

const EventCharts = ({ sliderValues }) => {
    const { t } = useTranslation();

    const { data: event } = useSelector((s) => s.eventDetail);

    const [eventChart, setEventChart] = useState({
        stressLevels: [],
        oxygens: [],
        temperatures: [],
        crowdingAreas: [],
        currentSpeeds: [],
        numberOfFishes: [],
        stressZones: [],
        tonPerHours: []
    });

    const [toggleEfficiencyState, setToggleEfficiencyState] = useState(
        EFFICIENCY_TOGGLE.FISH_PER_30_SECONDS
    );

    useEffect(() => {
        const stressLevels = reduce(
            event.stressLevels,
            (accumulator, stress) => {
                const differenceInSeconds = getDifferenceInSeconds(
                    event.startTime,
                    stress.stop_time
                );

                if (isTimeWithinSliderValues(differenceInSeconds))
                    accumulator.push({
                        differenceInSeconds: differenceInSeconds,
                        totalSeconds: getDifferenceInSeconds(stress.start_time, stress.stop_time),
                        time: formatSeconds(differenceInSeconds),
                        value: stress.level
                    });

                return accumulator;
            },
            []
        );

        const crowdingAreas = reduce(
            event.crowdingAreas,
            (accumulator, crowdingArea) => {
                const differenceInSeconds = getDifferenceInSeconds(
                    event.startTime,
                    crowdingArea.stop_time
                );

                if (isTimeWithinSliderValues(differenceInSeconds))
                    accumulator.push({
                        time: formatSeconds(differenceInSeconds),
                        value: crowdingArea.area
                    });

                return accumulator;
            },
            []
        );

        const oxygens = reduce(
            event.oxygens,
            (accumulator, oxygen) => {
                const differenceInSeconds = getDifferenceInSeconds(event.startTime, oxygen.date);

                if (isTimeWithinSliderValues(differenceInSeconds))
                    accumulator.push({
                        time: formatSeconds(differenceInSeconds),
                        value: oxygen.value
                    });

                return accumulator;
            },
            []
        );

        const temperatures = reduce(
            event.temperatures,
            (accumulator, temperature) => {
                const differenceInSeconds = getDifferenceInSeconds(
                    event.startTime,
                    temperature.date
                );

                if (isTimeWithinSliderValues(differenceInSeconds))
                    accumulator.push({
                        time: formatSeconds(differenceInSeconds),
                        value: temperature.value
                    });

                return accumulator;
            },
            []
        );

        const numberOfFishes = flatMap(event.channels, (channel) => {
            var previousNumberFish;
            return channel.numberFishes
                ?.map((numberFish) => {
                    const differenceInSeconds = getDifferenceInSeconds(
                        event.startTime,
                        numberFish.stop_time
                    );

                    const newNumberFish = {
                        type: `${t('eventDetail.channel')} ${channel.channelName}`,
                        differenceInSeconds: differenceInSeconds,
                        time: formatSeconds(differenceInSeconds),
                        value:
                            numberFish.value &&
                            previousNumberFish &&
                            numberFish.value > previousNumberFish
                                ? numberFish.value - previousNumberFish
                                : 0
                    };
                    previousNumberFish = numberFish.value;

                    return newNumberFish;
                })
                .filter((numberFish) => isTimeWithinSliderValues(numberFish.differenceInSeconds));
        });

        const tonPerHours = flatMap(event.channels, (channel) => {
            return channel.tonPerHours
                ?.map((tonPerHour) => {
                    const differenceInSeconds = getDifferenceInSeconds(
                        event.startTime,
                        tonPerHour.stop_time
                    );

                    const newTonPerHour = {
                        type: `${t('eventDetail.channel')} ${channel.channelName}`,
                        differenceInSeconds: differenceInSeconds,
                        time: formatSeconds(differenceInSeconds),
                        value: tonPerHour.value < 0 ? 0 : tonPerHour.value
                    };

                    return newTonPerHour;
                })
                .filter((tonPerHour) => isTimeWithinSliderValues(tonPerHour.differenceInSeconds));
        });

        const stressZones = map(STRESS_ZONES, (stressZone) => ({
            name: stressZone.name,
            value: stressLevels
                .filter(
                    (stressLevel) =>
                        stressZone.min <= stressLevel.value &&
                        stressLevel.value <= stressZone.max &&
                        isTimeWithinEventThrowTimes(stressLevel.differenceInSeconds)
                )
                .reduce((accumulator, stressLevel) => accumulator + stressLevel.totalSeconds, 0)
        }));

        const currentSpeeds = flatMap(event.channels, (channel) => {
            return reduce(
                channel.currentSpeeds,
                (accumulator, speed) => {
                    const differenceInSeconds = getDifferenceInSeconds(
                        event.startTime,
                        speed.stop_time
                    );

                    if (isTimeWithinSliderValues(differenceInSeconds))
                        accumulator.push({
                            type: `${t('eventDetail.channel')} ${channel.channelName}`,
                            time: formatSeconds(differenceInSeconds),
                            value: speed.value || 0
                        });

                    return accumulator;
                },
                []
            );
        });

        setEventChart({
            stressLevels,
            oxygens,
            temperatures,
            crowdingAreas,
            currentSpeeds,
            numberOfFishes,
            stressZones,
            tonPerHours
        });
    }, [sliderValues]);

    const eventThrowTimes = event.throws?.map((eventThrow) => ({
        startTime: getDifferenceInSeconds(event.startTime, eventThrow.startTime),
        stopTime: getDifferenceInSeconds(event.startTime, eventThrow.stopTime)
    }));

    const isTimeWithinSliderValues = (seconds) =>
        !sliderValues || (seconds >= sliderValues[0] && seconds <= sliderValues[1]);

    const isTimeWithinEventThrowTimes = (seconds) => {
        if (!eventThrowTimes?.length) return true;

        return eventThrowTimes.some((eventThrow) => {
            return eventThrow.startTime <= seconds && seconds <= eventThrow.stopTime;
        });
    };

    const handleToggleEfficiencyStateChange = (state) => {
        setToggleEfficiencyState(state);
    };

    return (
        <>
            <Row gutter={8}>
                <Col xs={24} xl={12}>
                    <Card className="mt-2">
                        <p className="font-medium text-base">{t('eventDetail.stressLevel')}</p>
                        <SBLine
                            data={eventChart.stressLevels}
                            height={300}
                            isSingleLine={true}
                            color={BLUE}
                            chartConfig={GRADIENT_CHART_CONFIG}
                            style={{
                                background: generateGradientColor({
                                    colors: [LIGHT_RED, LIGHT_ORANGE, LIGHT_YELLOW, LIGHT_GREEN]
                                })
                            }}
                        />
                    </Card>
                </Col>

                <Col xs={24} xl={12}>
                    <Card className="mt-2">
                        <p className="font-medium text-base">{t('eventDetail.stressZone')}</p>
                        <SBBar
                            data={eventChart.stressZones}
                            colors={[GRAY, RED, ORANGE, YELLOW, GREEN]}
                            height={300}
                            formatValue={formatSeconds}
                        />
                    </Card>
                </Col>

                <Col xs={24} xl={12}>
                    <Card className="mt-2">
                        <p className="font-medium text-base">{t('eventDetail.oxygenLevel')}</p>
                        <SBLine
                            data={eventChart.oxygens}
                            height={300}
                            isSingleLine={true}
                            color={BLUE}
                            chartConfig={{
                                ...OXYGEN_CHART_CONFIG,
                                xAxis: { position: 'top' },
                                reflect: 'y'
                            }}
                            style={{
                                background: generateGradientColor({
                                    colors: [
                                        LIGHT_RED,
                                        LIGHT_ORANGE,
                                        LIGHT_YELLOW,
                                        LIGHT_GREEN,
                                        LIGHT_YELLOW
                                    ],

                                    stops: oxygenGradient.stops
                                })
                            }}
                        />
                    </Card>
                </Col>

                <Col xs={24} xl={12}>
                    <Card className="mt-2">
                        <div className="flex justify-between">
                            <p className="font-medium text-base">{t('eventDetail.efficiency')}</p>
                            <div className="flex justify-end">
                                <Button
                                    className={
                                        toggleEfficiencyState ===
                                        EFFICIENCY_TOGGLE.FISH_PER_30_SECONDS
                                            ? BUTTON_BLUE_SELECTED
                                            : BUTTON_BLUE_UN_SELECTED
                                    }
                                    onClick={() =>
                                        handleToggleEfficiencyStateChange(
                                            EFFICIENCY_TOGGLE.FISH_PER_30_SECONDS
                                        )
                                    }
                                    style={{ marginRight: '4px' }}
                                >
                                    {t('eventDetail.fishPer30Seconds')}
                                </Button>

                                <Button
                                    className={
                                        toggleEfficiencyState === EFFICIENCY_TOGGLE.TON_PER_HOUR
                                            ? BUTTON_BLUE_SELECTED
                                            : BUTTON_BLUE_UN_SELECTED
                                    }
                                    onClick={() =>
                                        handleToggleEfficiencyStateChange(
                                            EFFICIENCY_TOGGLE.TON_PER_HOUR
                                        )
                                    }
                                >
                                    {t('eventDetail.tonPerHour')}
                                </Button>
                            </div>
                        </div>
                        <SBLine
                            data={
                                toggleEfficiencyState === EFFICIENCY_TOGGLE.FISH_PER_30_SECONDS
                                    ? eventChart.numberOfFishes
                                    : eventChart.tonPerHours
                            }
                            height={300}
                        />
                    </Card>
                </Col>

                <Col xs={24} xl={12}>
                    <Card className="mt-2">
                        <p className="font-medium text-base">{t('eventDetail.temperature')}</p>
                        <SBLine
                            data={eventChart.temperatures}
                            height={300}
                            isSingleLine={true}
                            color={ORANGE}
                            chartConfig={TEMPERATURE_CHART_CONFIG}
                        />
                    </Card>
                </Col>

                <Col xs={24} xl={12}>
                    <Card className="mt-2">
                        <p className="font-medium text-base">{t('eventDetail.currentSpeed')}</p>
                        <SBLine data={eventChart.currentSpeeds} height={300} />
                    </Card>
                </Col>

                <Col xs={24} xl={12}>
                    <Card className="mt-2">
                        <p className="font-medium text-base">{t('eventDetail.crowdingArea')}</p>
                        <SBLine
                            data={eventChart.crowdingAreas}
                            height={300}
                            isSingleLine={true}
                            color={GREEN}
                            chartConfig={CROWDING_CHART_CONFIG}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    );
};

EventCharts.propTypes = {
    sliderValues: propTypes.array
};

export default EventCharts;
